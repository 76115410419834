import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { baseUrl, api, devMode } from "../config";

const OpenApiExplorer = ({ specUrl, disableTryItOut }) => {
  const explorerRef = useRef(null);

  useEffect(() => {
    // Original fetch sichern
    const originalFetch = window.fetch;

    // Custom fetch zum Abfangen aller Requests
    const customFetch = async (input, options = {}) => {
      let method = "GET"; // Standardmethode

      // Prüfen, ob `input` ein Request-Objekt ist
      if (input instanceof Request) {
        method = input.method.toUpperCase();
        console.log("Request object detected:", input);
      } else if (options.method) {
        method = options.method.toUpperCase();
        console.log("Options method detected:", method);
      }

      console.log("Intercepted Request Method:", method);

      // Blockiere nicht-GET-Requests
      if (disableTryItOut && method !== "GET") {
        console.warn(`Blocked ${method} request to ${input}`);
        alert(`Requests with method ${method} are disabled.`);
        return Promise.reject(new Error(`all requests which can potentially alter the data on the prodcution server are being disbled here`));
      }

      // Anfrage fortsetzen, wenn erlaubt
      const modifiedOptions = {
        ...options,
        credentials: "include", // Cookies aktivieren
      };

      return originalFetch(input, modifiedOptions);
    };

    // Fetch patchen
    window.fetch = customFetch;

    // Skript für den OpenAPI Explorer laden
    const script = document.createElement("script");
    script.type = "module";
    script.src = "https://unpkg.com/openapi-explorer@0/dist/browser/openapi-explorer.min.js";

    script.onload = () => {
      if (explorerRef.current) {
        explorerRef.current.setAttribute("spec-url", specUrl);
      }
    };

    document.head.appendChild(script);

    return () => {
      // Cleanup: Original fetch wiederherstellen und Script entfernen
      window.fetch = originalFetch;
      document.head.removeChild(script);
    };
  }, [specUrl, disableTryItOut]);

  return <div><openapi-explorer ref={explorerRef}></openapi-explorer></div>;
};

const ApiExplorer = () => {
  const { app, path } = useParams();

  const specUrl = path
    ? `https://${api}.${baseUrl}/docu/${app}/%2F${path}/docs`
    : `https://${api}.${baseUrl}/docu/${app}/docs`;

  useEffect(() => {
    const onRequest = (data) => {
      console.log("Request:", data);
    };

    // Event-Listener hinzufügen
    window.addEventListener("request", (e) => onRequest(e.detail));

    // Cleanup
    return () => {
      window.removeEventListener("request", (e) => onRequest(e.detail));
    };
  }, []);

  return <OpenApiExplorer specUrl={specUrl} disableTryItOut={!devMode} />;
};

export default ApiExplorer;
