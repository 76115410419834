import React from 'react'
//import ReactDOM from 'react-dom'
import ReactDOM from 'react-dom/client'
import './css/index.css'
import App from './App'
import ConnectionError from './lib20/ConnectionError'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
//import reportWebVitals from './reportWebVitals'
import KPEContextProvider from '@wollo-lib/kpe-context'
import KPE20context from './lib20/KPE20context'
import {basePath}       from './config.jsx'
import {BrowserRouter as Router} from 'react-router-dom'

if(window.location.pathname!=='/connectionError')
{
    ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        
        <KPEContextProvider>
        <KPE20context>
        <Router basename={basePath} >
            <App />
        </Router>
        </KPE20context>
        </KPEContextProvider>
    </React.StrictMode>
    )
}
else
{
    ReactDOM.createRoot(document.getElementById('root')).render(
        <React.StrictMode>
            <ConnectionError/>
        </React.StrictMode>
    )
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
