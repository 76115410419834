import React from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { baseUrl, api, devMode} from "../config";
import { useParams } from "react-router-dom";

const SwaggerEditor = () => {
  const { app, path } = useParams();

  const specUrl = path
    ? `https://${api}.${baseUrl}/docu/${app}/%2F${path}/docs`
    : `https://${api}.${baseUrl}/docu/${app}/docs`;

  const isProduction = ! devMode 

  const blockNonGetRequests = (req) => {
    const method = req.method ? req.method.toUpperCase() : "GET"; // Default to GET if undefined

    if (isProduction && method !== "GET") {
      alert(`Non-GET requests are disabled in the production environment.`);
      console.warn(`Blocked ${method} request to ${req.url}`);
      throw new Error(`Requests with method ${method} are disabled in production.`);
    }
    return req;
  };

  return (
    <SwaggerUI
      url={specUrl}
      withCredentials={true}
      requestInterceptor={blockNonGetRequests}
    />
  );
};

export default SwaggerEditor;
