import React, {useState, useEffect}  from 'react'
import {Dropdown,  Menu, Image, Icon, Flag, Sidebar, Segment, Divider, Label, DropdownDivider} from 'semantic-ui-react'
import {ReactComponent as Logo} from '../css/LilieWhite.svg'

import {useHistory, useLocation} from 'react-router-dom'
//import {SearchSelect} from '@wollo-lib/wollo-react'
import ModalRail from '../lib20/Rails/ModalRail'
import {useWindowDimensions, useApi, useUser} from '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import useTexts from '../lib20/useTexts'
import {useRoot, useConfig, useWsStatus, useFakeUser} from '../lib20/KPE20context'
import {baseUrl} from '../config.jsx'

import DropdownModalNew from './DropdownModalNew'
import AvatarMenu from '../lib20/Menu/AvatarMenu'
import MenuHelp from '../lib20/Menu/MenuHelp'
import ChangeLog, {NewVersion} from '../lib20/Changelog/Changelog'

import ConnectionStatus from '../lib20/Menu/ConnectionStatus'
import SystemMessage from '../lib20/SystemMessage'
import DropdownMenuDocu from './DropdownMenuDocu.jsx'
import {version} from '../config'


const MenuItems=(props) => {
    const history=useHistory()
    const texts=useTexts('Menu')
    const config=useConfig()


    const {setSidebarVisible,functions,achievements,setModalParams,isAdmin,extraMenu,isDesktop,tabRef}=props

 
    const onClick=(ev,{name})=>
    {
        history.push(name)
        setSidebarVisible(false)
    }

    
    
        


        
    const adminMenu= !isAdmin ? null:
        <Dropdown item simple={isDesktop} text={texts.Admin} key='admin'>
            <Dropdown.Menu key='MenuAdmin'>
            
              
               
                <Dropdown.Item
                    onClick={onClick}
                    key='Admins'
                    text={texts.Admins}
                    name='/admins'
                    icon="user secret"
                />
                <Dropdown.Item
                    onClick={onClick}
                    key='Achievements'
                    text={texts.Achievements}
                    name='/achievements'
                    icon="tag"
                />
                <Dropdown.Item
                    onClick={onClick}
                    key='functions'
                    text={texts.Functions}
                    name='/functions'
                    icon='id badge'
                />
                <Dropdown.Item
                    onClick={onClick}
                    key='actions'
                    text={texts.Actions}
                    name='/actions'
                    icon='magic'
                />
                <Dropdown.Divider key='diva'/>
              

                <Dropdown.Item
                    onClick={onClick}
                    key='maintenance'
                    text={texts.Maintenance}
                    name='/maintenance'
                    icon='database'
                />

                <Dropdown.Divider key='divb'/>

                <DropdownModalNew
                    component={ModalRail}
                    key={'rail'}
                    text={texts.DashboardRailDB}
                    modalProps={{
                            railName:'db',
                            railPath: 'db',
                            setModalParams: setModalParams
                    }}
                    myKey={'ItemRail'}
                    setModalParams={setModalParams}
                    setSidebarVisible={setSidebarVisible}
                    icon='train'
                />

                <DropdownModalNew
                    component={ModalRail}
                    key={'railEvents'}
                    text={texts.EventsRail}
                    modalProps={{
                            railName:'events',
                            railPath: 'events',
                            setModalParams: setModalParams
                    }}
                    myKey={'ItemRailEvents'}
                    setModalParams={setModalParams}
                    setSidebarVisible={setSidebarVisible}
                    icon='train'
                />

                <DropdownModalNew
                    component={ModalRail}
                    key={'railAdmin'}
                    text={texts.AdminRail}
                    modalProps={{
                            railName:'admin',
                            railPath: 'admin',
                            setModalParams: setModalParams
                    }}
                    myKey={'ItemRailEvents'}
                    setModalParams={setModalParams}
                    setSidebarVisible={setSidebarVisible}
                    icon='train'
                />
                
            </Dropdown.Menu>
        </Dropdown>
    const orgaMenu= !isAdmin ? null:
        <Dropdown item simple={isDesktop} text={texts.Orgas} key='orga'>
            <Dropdown.Menu key='MenuAdmin'>
                <Dropdown.Item
                    onClick={onClick}
                    key='Orgas'
                    text={texts.Orgas}
                    name='/orgas'
                    icon="sitemap"
                />
            </Dropdown.Menu>
        </Dropdown>
     const docuMenu= 
        <DropdownMenuDocu 
            isDesktop={isDesktop}
            onClick={onClick}
        />
   
        
    return [adminMenu,orgaMenu, docuMenu]

}


const MainMenu=(props) => {

    const history=useHistory()    
    const config=useConfig()
    const user=useUser()
    const {fakeUser}=useFakeUser()
    const fakeUserMenuColor = 'orange'


    const texts=useTexts('Menu')
    const {setModalParams}=props    
    const [sidebarVisible, setSidebarVisible]=useState(false)
    const [changelogVisible, setChangelogVisible] = useState(false)


    const windowWidth=useWindowDimensions().width
    const [isDesktop, setDesktop]=useState(true)
    useEffect(()=>{
        windowWidth< 800 ? setDesktop(false) : setDesktop(true)
    }, [windowWidth])

    const isTouch= 
        ( 'ontouchstart' in window ) ||
               ( navigator.maxTouchPoints > 0 ) ||
               ( navigator.msMaxTouchPoints > 0 );
    

    return (<>

        <ChangeLog visible={changelogVisible} setVisible={setChangelogVisible} />
        <NewVersion/>
        <div style={{marginBottom:'20px'}}>
            {
                isDesktop ? 
                
                        <Menu attached color={fakeUser ? fakeUserMenuColor : 'blue'} inverted  key='MainMenuMenu'>
                            <Menu.Item
                                key='logo'
                                onClick={el=>{history.push('/')}}
                            >
                                <Logo width={35} height={35}/>
                                <ConnectionStatus key='ConnectionStatus' className='desktop' />
                            </Menu.Item>

                            <MenuItems {...props} setSidebarVisible={setSidebarVisible} isDesktop={true} key='MainMenuItems'/>
                            <MenuHelp
                                setModalParams={setModalParams}
                                setSidebarVisible={setSidebarVisible}
                            />
                            
                            
                            <Dropdown icon={null} as={Menu.Item} direction='left' position='right' key='avatar' trigger={
                                config?.orgaUser?.Data?.avatar 
                                ? <Image src={config.orgaUser.Data.avatar} circular size='mini' />
                                : <Icon name='user' circular key='IconUser'/>
                            }>
                                <Dropdown.Menu >
                                    <Menu.Header style={{textAlign:'center'}}>{config.orgaUser.Data.firstName} {config.orgaUser.Data.lastName}</Menu.Header>
                                    <p style={{color:'grey', fontSize:'70%', textAlign:'center'}}>{config.AppName} v{version}</p>
                                    <AvatarMenu {...props} setSidebarVisible={setSidebarVisible} setChangelogVisible={setChangelogVisible} isDesktop={true} windowWidth={windowWidth} setModalParams={setModalParams}/>
                                </Dropdown.Menu>
                            </Dropdown>

                        </Menu>
                        

                :
                    <Menu attached color={fakeUser ? fakeUserMenuColor : 'blue'} inverted >
                        <Dropdown item icon='bars' onClick={e=>setSidebarVisible(status=>!status)} direction='left' key='MainBars' >
                            <ConnectionStatus className='mobile'/>
                        </Dropdown>
                        <Sidebar visible={sidebarVisible} 
                            onHide={() => setSidebarVisible(false)}
                            animation='overlay'
                            as={Segment} color={fakeUser ? fakeUserMenuColor : 'blue'} inverted vertical key='SideBar'
                        >
                            <Menu.Item onClick={() => setSidebarVisible(false)} icon='close' header content={user.fullName} key='SideClose'/>
                            <Divider key='SideDiv1'/>
                            <p style={{fontSize:'70%', marginLeft: '20px'}}>{config.AppName} v{version}</p>
                            <AvatarMenu {...props} isDesktop={false} setSidebarVisible={setSidebarVisible} setChangelogVisible={setChangelogVisible} windowWidth={windowWidth}/>
                            <MenuHelp
                                setModalParams={setModalParams}
                                setSidebarVisible={setSidebarVisible}
                            />
                            <Divider />
        
                            <MenuItems setSidebarVisible={setSidebarVisible} {...props} isDesktop={false}/>
                        
                        </Sidebar>
                                

                    </Menu>
            }
            <SystemMessage/>
        </div>


    </>)
}
export default MainMenu
