import React,{useEffect, useState} from "react";
import {Header,Accordion, List, Icon, Button} from 'semantic-ui-react'
import {useHistory} from 'react-router-dom'

import { api, baseUrl, apps } from '../config';
import {useApi} from '@wollo-lib/kpe-context' 

const EndpointList = (props) => {
    const history=useHistory()
    const {apiSelect}= useApi()
    const [list,setList]=useState([])
    const [activeIndices, setActiveIndices]=useState([])
    useEffect(()=>{
        const getList=async()=>
        {
            const endpoints= await Promise.all(apps.map(app=>
                new Promise(async (fullfill,reject)=>{
                    const result= await apiSelect(`/docu/${app}/index`)
                    if(result.success)
                    {
                        fullfill({app: app, result:result.result})
                    }
                    else
                    {
                        fullfill({app: app, result:null})
                    }
                })
            ))
            setList(endpoints)

        }
        getList()
    },[])
    const openRedoc=(ev,{index,name})=>
    {
        
        history.push(`/apiexplorer/${list[index].app}/${encodeURIComponent(name)}`)
    }
    const openSwagger=(ev,{index,name})=>
    {
        history.push(`/swagger/${list[index].app}/${encodeURIComponent(name)}`)
    }
    const accordionClick=(ev,{index})=>
    {

        if(activeIndices.includes(index))
            setActiveIndices(active=>active.filter(i=>i!==index))
        else
            setActiveIndices(active=>([...active,index]))
    }
    
    return(<>
        <Header as='h2'>API Endpoints</Header>
        <Accordion exclusive={false} styled>
        {
            list.map((ep, aIndex)=>{
                const active= activeIndices.includes(aIndex)
                return (<>
                    <Accordion.Title as='h2'   
                        active={active}
                        index={aIndex}
                        onClick={accordionClick}
                    >
                    <Icon name='dropdown' />
                        {ep.app} Endpoints
                    </Accordion.Title>
                    <Accordion.Content active={active}>
                       
                        <List>
                        {
                            Object.entries(ep.result?.paths ?? {}).map(([path,data],index)=>
                                <List.Item key={path} index={aIndex} name={path} >
                                    <List.Icon name='external alternate' size='large' verticalAlign='middle' />
                                        <List.Content path={path} url={ep.result.servers[0]}>
                                            <List.Header>
                                                {path} 
                                                <Button basic color='blue' content='explorer' size='mini' index={aIndex} name={path.substring(1)} onClick={openRedoc}/> 
                                                <Button basic color='green' content='swagger' size='mini' index={aIndex} name={path.substring(1)} onClick={openSwagger}/> 
                                            </List.Header>
                                            <List.Description >{data.description}</List.Description>
                                        </List.Content>
                                </List.Item>
                            )   
                        }
                        </List>
                    </Accordion.Content>
                    </>
                )})
        }
        </Accordion>
    </>)



    
};
  
export default  EndpointList