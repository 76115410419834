import React,{ useState, useReducer,useEffect, useRef }  from 'react';
import {Container,Header,Icon,Button, List, Menu, Segment} from 'semantic-ui-react'
import useTexts from './lib20/useTexts'
import {useRoot,useSetConfig,useConfig} from './lib20/KPE20context'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import { api, baseUrl, apps } from './config';
import FileEditor from "./lib20/FileEditor"
import YAML from 'yaml'

const yamlValidator=(text)=>
{
    const res={ success: true, error: null}
    try
    {
        YAML.parse(text)
        return(res)
    }
    catch(e)
    {
        return {success: false, error: e}
    }

}


const jsonValidator=(text)=>
{
    const res={ success: true, error: null}
    try
    {
        JSON.parse(text)
        return(res)
    }
    catch(e)
    {
        return {success: false, error: e}
    }

}


const ConfigFile=(props)=>
{

  
    const texts = useTexts('lib20Texts','Languages')

    const {setModalParams}=props

    const [languages, setLanguages]= useState([])
    const {apiSelect,apiDelete,apiPutFile, apiGetFile}=useApi()
    const [currentRoot,]=useRoot()
    const setConfig=useSetConfig()
    const config=useConfig()
    const [loading,setLoading]=useState(false)
    const [timestamp,setTimestamp]=useState(Date.now())
    const [app,setApp]= useState('admin')


    useEffect(()=>{
        const getLanguages=async ()=>
        {
            const result=await apiSelect(`/kpe20/languages/${app}`)
            if(result.success)
                setLanguages(result.files)
        }
        getLanguages()
    },[timestamp,app])

    const uploadFile=async (e)=> {

        setLoading(true)
        const myFile = e.target.files[0]
        const result=await apiPutFile(`/kpe20/config/${app}`,myFile,`${config.root}.yaml`,'') 
        setLoading(false)
        if(result.success)
        {
            setConfig({...result.config,orgaUser: config.orgaUser})
            setTimestamp(Date.now())
        }
        else
            alert(result.message)
    }

    const editConfigFile=async (e,{name})=>
    {
        setModalParams({
            addLevel:
            {
                component: FileEditor,
                getUrl: `/kpe20/config/${app}/${name}`,
                putUrl: `/kpe20/config/${app}`,
                filename: name+'.yaml',
                language:'yaml',
                defaultCode:"",
                mimeType:"application/yaml",
                size: 'large',
                validator: yamlValidator

            }
        })  
    }
   

    const uploadLanguageFile=async (e)=> {
        setLoading(true)
        const myFile = e.target.files[0]
        const result=await apiPutFile(`/kpe20/languages/${app}`,myFile,myFile.name,'') 
        setLoading(false)
        if(result.success)
        {
            setTimestamp(Date.now())
        }
        else
            alert(result.message)
    }

    
    const editChangelogFile=async (e,{name})=>
    {
        setModalParams({
            addLevel:
            {
                component: FileEditor,
                getUrl: `/kpe20/files/${app}/config/changelog.yaml`,
                putUrl: `/kpe20/files/changelog/${app}`,
                filename: 'changelog.yaml',
                language:'yaml',
                defaultCode:"",
                mimeType:"application/yaml",
                size: 'large',
                validator: yamlValidator

            }
        })  
    }
      
    const editLogFile=async (e,{name})=>
    {
        setModalParams({
            addLevel:
            {
                component: FileEditor,
                getUrl: `/kpe20/files/${app}/logs`,
                putUrl: null,
                filename: 'requestLog.yaml',
                language:'yaml',
                defaultCode:"",
                mimeType:"application/yaml",
                size: 'large',
                validator: yamlValidator

            }
        })  
    }
    const editLanguageFile=async (e,{name})=>
    {
        setModalParams({
            addLevel:
            {
                component: FileEditor,
                getUrl: `/kpe20/languages/${app}/${name}`,
                putUrl: `/kpe20/languages/${app}`,
                filename: name,
                language:'json',
                defaultCode:"{}",
                mimeType:"application/json",
                size: 'large',
                validator: jsonValidator

            }
        })  
    }

    const updateLanguageModel=async ()=>
    {
        let newFiles=false
        // fix all missing translations in all lanuages with a machine translation for the original language model
        if(window.confirm('Proceed?'))
        {
          
            let cloneTexts
            if(app !== 'admin')
            {
                await new Promise((fullfill,reject)=>
                {
                    const script = document.createElement("script");
                    script.async = true;
                    script.src = `${config.AppUrls[app]}/languageMaster.js`;
                    document.head.appendChild(script);
                    script.onload = () => {
                       
                        fullfill()
                    }
                })
            }
            else
            {
                cloneTexts=window.adminTexts
            }
        
       

            const contextModule= await import('./lib20/lib20Texts')
            const contextTexts=contextModule.contextTexts
            cloneTexts=window[`${app}Texts`]
            if(!cloneTexts)
            {
                window.alert(`something went wrong, no lanuage master found for ${app}`)
            }
            else
            {
                await Promise.all(
                Object.entries(config.LanguagesTranslated).map(([language,languageCode])=> new Promise(async (fullfill,reject)=>
                {
                    //upload the languageMaster with the translate code and translate flag
                    // the api keeps the transaltions, already existant from the old tranlation files, if one already exists
                    // translate every other language, despite german
                    cloneTexts.translate= languageCode!=='de'
                    cloneTexts.language=languageCode
                    // add the text master from lib20
                    cloneTexts.lib20Texts=contextTexts
                    // upload the actual languageMaster for this app with the tranlation request included
                    const newText=JSON.stringify(cloneTexts,null,4)
                    const file = new Blob([newText], {type:'application/json'});
                    const result=await apiPutFile(`/kpe20/languages/${app}`,file,language+'.json','') 
                    if(!result)
                    {
                        window.alert('something went wrong with language '+language)
                        reject() 
                    }
                    else
                    {
                        if(result.files[0].newFile)
                            newFiles=true
                        fullfill()
                    }
                    
                })))
                if(newFiles)
                    setTimestamp(Date.now())
                window.alert('done')
            }

        }

    }

    const rebuildLanguageModel=async (ev,{name})=>
    {
        const targetLanguage=name
        // completely rebuild this language with machine translation from the base
        let cloneTexts
        if(window.confirm('Proceed?'))
        {
          

            if(app !== 'admin')
            {
                await new Promise((fullfill,reject)=>
                {
                    const script = document.createElement("script");
                    script.async = true;
                    script.src = `${config.AppUrls[app]}/languageMaster.js`;
                    document.head.appendChild(script);
                    script.onload = () => {
                       
                        fullfill()
                    }
                })
            }
            else
            {
                cloneTexts=window.adminTexts
            }
        
       

            const contextModule= await import('./lib20/lib20Texts')
            const contextTexts=contextModule.contextTexts
            cloneTexts=window[`${app}Texts`]
            if(!cloneTexts)
            {
                window.alert(`something went wrong, no lanuage master found for ${app}`)
            }
            else
            {
                const [language, languageCode]=Object.entries(config.LanguagesTranslated).find(([language,languageCode])=> language===targetLanguage)
                if(languageCode==='de')
                    return
                
                //upload the languageMaster with the translate code and translate flag
                // the api keeps the transaltions, already existant from the old tranlation files, if one already exists
                // translate every other language, despite german
                cloneTexts.translate= languageCode!=='de'
                cloneTexts.language=languageCode
                cloneTexts.rebuild= languageCode!=='de'
                // add the text master from lib20
                cloneTexts.lib20Texts=contextTexts
                // upload the actual languageMaster for this app with the tranlation request included
                const newText=JSON.stringify(cloneTexts,null,4)
                const file = new Blob([newText], {type:'application/json'});
                const result=await apiPutFile(`/kpe20/languages/${app}`,file,language+'.json','') 
                if(!result)
                {
                    window.alert('something went wrong with language '+language)
                   
                }
                else
                {
                   setTimestamp(Date.now())
                   window.alert('done')
                }
            }

        }

    }




    return(
    <Container text>
        <Menu tabular attached="top" >
            {
                apps.map(a=>(
                    <Menu.Item
                        key={a}
                        active={app === a}
                        onClick={()=>setApp(a)}
                        name={a}
                        content={a}
                    />
                ))
            }
           
        </Menu>
        <Segment attached="bottom">
            
            <List>
                <Header>Config File</Header>
                             
                <List.Item>
                    <a href={`https://${api}.${baseUrl}/kpe20/config/${app}/${config.UID}`}>{config.AppName} {config.Orga}</a>&nbsp;

                    <Button  as="h3" color='blue' icon='edit' name={`${config.UID}`} onClick={editConfigFile}/>
                </List.Item>
                <Button  htmlFor='file2' 
                        type='button' as='label' width={1} loading={loading}>
                        {loading===true ? '' : <>
                        <input type='file' id='file2'  accept='.json'  style={{display: 'none'}} onChange={uploadFile}/>
                                <Icon name='upload' color='green'/> Upload Config File</>
                        }
                </Button>
                <List.Item>
                    <Button  as="h3" color='orange' icon='eye' content="Log File" onClick={editLogFile}/>
                </List.Item>

                <Header content='Changelog File'/>

                <List.Item>
                    <Button  as="h3" color='blue' icon='edit' content="changelog" onClick={editChangelogFile}/>
                </List.Item>
                

             
                
            </List>

            <Header>Language Files</Header>
            <p>{`
                In order to generate a new language file, enter it in the LanguagesTranslated section in the admin app config file
            `
            }
            </p>
            <List>
                {
                

                    languages.map(lfile=>
                    
                        <List.Item>
                            <a href={lfile.url}>{`${texts[lfile.name.replace('languages/','').replace('.json','')]} (${lfile.name.replace('languages/','')})`}</a>{'\t'}
                            <Button basic  icon='edit' onClick={editLanguageFile} name={lfile.name.replace('languages/','')}></Button>
                            <Button content='rebuild' onClick={rebuildLanguageModel} name={lfile.name.replace('languages/','').replace('.json','')}></Button>
                        </List.Item>

                    )
                }
                <Button  htmlFor='file3' 
                        type='button' as='label' width={1} loading={loading}>
                        {loading===true ? '' : <>
                        <input type='file' id='file3'  accept='.json'  style={{display: 'none'}} onChange={uploadLanguageFile}/>
                                <Icon name='upload' color='green'/> Upload Language File</>
                        }
                </Button>
            </List>
            <p>
                Pressing this button will update all existing language file with missing automatic translations
            </p>
            <Button 
                content='Update all language files'
                onClick={()=>updateLanguageModel(languages,apiGetFile,apiPutFile)}
                
            />
            <Button 
                content='Completely rebuild all language files'
                onClick={()=>rebuildLanguageModel(languages,apiGetFile,apiPutFile)}
                
            />
            
        </Segment>
       
    </Container>
    )

}
export default ConfigFile