import React, {useState, useEffect}  from 'react'
import {Dropdown,  Menu, Image, Icon, Flag, Sidebar, Segment, Divider, Label, DropdownDivider} from 'semantic-ui-react'

const DropdownMenuDocu=(props)=>
{
    const {text,setSidebarVisible,component,setModalParams,icon,modalProps,myKey}=props
    const openModalNew=(ev ) => {
       setModalParams({addLevel:{component:component,createNew:true,...modalProps}})
       setSidebarVisible(false)
    }
    const {isDesktop, onClick}=props
    return (
        <Dropdown item simple={isDesktop} text='API-Documentation' key='admin'>
            <Dropdown.Menu key='MenuDocu'>

                <Dropdown.Item
                    onClick={onClick}
                    key='Admins'
                    text='Api endpoints'
                    name='/endpoints'
                    icon="document"
                />
            </Dropdown.Menu>
        </Dropdown>
       
    )

}

export default DropdownMenuDocu